import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';

const calc = document.getElementById('calc');
if(calc){
	ReactDOM.render(<App/>, document.getElementById('calc'));
}


